/* Dependencies */
import type { NextPage } from 'next';
import Head from 'next/head';
import Link from 'next/link';

/**
 * Custom Error Page
 */
const CustomErrorPage: NextPage = () => {
  return (
    <>
      <Head>
        <title>MMT | Not Found</title>
      </Head>

      <div className="bg-white px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:min-h-screen lg:px-8">
        <div className="mx-auto max-w-max">
          <main className="sm:flex">
            <p className="text-4xl font-extrabold text-primary sm:text-5xl">
              404
            </p>
            <div className="sm:ml-6">
              <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">
                  Page not found
                </h1>
                <p className="mt-2 text-base text-gray-500">
                  Please check the URL in the address bar and try again.
                </p>
              </div>
              <div className="mt-6 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                <Link href="/">
                  <a className="text-indigo-700">Go back home</a>
                </Link>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

// Exports
export default CustomErrorPage;
